(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(['exports', 'react', 'prop-types', '../lib/String'], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require('react'), require('prop-types'), require('../lib/String'));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.react, global.propTypes, global.String);
    global.HeatMap = mod.exports;
  }
})(this, function (exports, _react, _propTypes, _String) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.HeatMap = undefined;

  var _react2 = _interopRequireDefault(_react);

  var _propTypes2 = _interopRequireDefault(_propTypes);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var evtNames = ['click', 'mouseover', 'recenter'];

  var wrappedPromise = function wrappedPromise() {
    var wrappedPromise = {},
        promise = new Promise(function (resolve, reject) {
      wrappedPromise.resolve = resolve;
      wrappedPromise.reject = reject;
    });
    wrappedPromise.then = promise.then.bind(promise);
    wrappedPromise.catch = promise.catch.bind(promise);
    wrappedPromise.promise = promise;

    return wrappedPromise;
  };

  var HeatMap = exports.HeatMap = function (_React$Component) {
    _inherits(HeatMap, _React$Component);

    function HeatMap() {
      _classCallCheck(this, HeatMap);

      return _possibleConstructorReturn(this, (HeatMap.__proto__ || Object.getPrototypeOf(HeatMap)).apply(this, arguments));
    }

    _createClass(HeatMap, [{
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.heatMapPromise = wrappedPromise();
        this.renderHeatMap();
      }
    }, {
      key: 'componentDidUpdate',
      value: function componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map || this.props.position !== prevProps.position) {
          if (this.heatMap) {
            this.heatMap.setMap(null);
            this.renderHeatMap();
          }
        }
      }
    }, {
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        if (this.heatMap) {
          this.heatMap.setMap(null);
        }
      }
    }, {
      key: 'renderHeatMap',
      value: function renderHeatMap() {
        var _this2 = this;

        var _props = this.props,
            map = _props.map,
            google = _props.google,
            positions = _props.positions,
            mapCenter = _props.mapCenter,
            icon = _props.icon,
            gradient = _props.gradient,
            _props$radius = _props.radius,
            radius = _props$radius === undefined ? 20 : _props$radius,
            _props$opacity = _props.opacity,
            opacity = _props$opacity === undefined ? 0.2 : _props$opacity,
            props = _objectWithoutProperties(_props, ['map', 'google', 'positions', 'mapCenter', 'icon', 'gradient', 'radius', 'opacity']);

        if (!google) {
          return null;
        }

        var data = positions.map(function (pos) {
          return { location: new google.maps.LatLng(pos.lat, pos.lng), weight: pos.weight };
        });

        var pref = _extends({
          map: map,
          gradient: gradient,
          radius: radius,
          opacity: opacity,
          data: data
        }, props);

        this.heatMap = new google.maps.visualization.HeatmapLayer(pref);

        this.heatMap.set('radius', radius === undefined ? 20 : radius);

        this.heatMap.set('opacity', opacity === undefined ? 0.2 : opacity);

        evtNames.forEach(function (e) {
          _this2.heatMap.addListener(e, _this2.handleEvent(e));
        });

        this.heatMapPromise.resolve(this.heatMap);
      }
    }, {
      key: 'getHeatMap',
      value: function getHeatMap() {
        return this.heatMapPromise;
      }
    }, {
      key: 'handleEvent',
      value: function handleEvent(evt) {
        var _this3 = this;

        return function (e) {
          var evtName = 'on' + (0, _String.camelize)(evt);
          if (_this3.props[evtName]) {
            _this3.props[evtName](_this3.props, _this3.heatMap, e);
          }
        };
      }
    }, {
      key: 'render',
      value: function render() {
        return null;
      }
    }]);

    return HeatMap;
  }(_react2.default.Component);

  HeatMap.propTypes = {
    position: _propTypes2.default.object,
    map: _propTypes2.default.object,
    icon: _propTypes2.default.string
  };

  evtNames.forEach(function (e) {
    return HeatMap.propTypes[e] = _propTypes2.default.func;
  });

  HeatMap.defaultProps = {
    name: 'HeatMap'
  };

  exports.default = HeatMap;
});