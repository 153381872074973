(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(['exports'], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.arePathsEqual = mod.exports;
  }
})(this, function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /**
   * Compares two path arrays of LatLng objects.
   */

  var arePathsEqual = exports.arePathsEqual = function arePathsEqual(pathA, pathB) {
    if (pathA === pathB) {
      return true;
    }
    if (!Array.isArray(pathA) || !Array.isArray(pathB)) {
      return false;
    }
    if (pathA.length !== pathB.length) {
      return false;
    }
    for (var i = 0; i < pathA.length; ++i) {
      if (pathA[i] === pathB[i]) {
        continue;
      }
      if (!isValidLatLng(pathA[i]) || !isValidLatLng(pathB[i])) {
        return false;
      }
      if (pathB[i].lat !== pathA[i].lat || pathB[i].lng !== pathA[i].lng) {
        return false;
      }
    }
    return true;
  };

  /**
   * Helper that checks whether an array consists of objects
   * with lat and lng properties
   * @param {object} elem the element to check
   * @returns {boolean} whether or not it's valid
   */
  var isValidLatLng = function isValidLatLng(elem) {
    return elem !== null && (typeof elem === 'undefined' ? 'undefined' : _typeof(elem)) === 'object' && elem.hasOwnProperty('lat') && elem.hasOwnProperty('lng');
  };
});